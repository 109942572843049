import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
import ActionsMenu from "./menus/ActionsMenu";

import {
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  COLORS,
  TYPOGRAPHY,
} from "../../shared/constants";
import { generatePushID } from "../../shared/utils/keyGenerator";
import MeetingContentCard from "./MeetingContentCard";
import MeetingOptionPanel from "./MeetingOptionPanel";
// import ConfirmationModal from "../../shared/ui/ConfirmationModal";
import {
  validateMeeting,
  updateMeetingTimeline,
  updateMeeting,
  updateMeetingPrepare,
} from "../../redux/actions/meetings";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "../../shared/ui/Buttons";
import {
  FlexContainer,
  ScrollContainer,
} from "../../shared/ui/Containers";
import styled from "styled-components";
import { Modal, ModalBody } from "shared/ui/Modals";
import { Icon, ICON_TYPES } from "shared/ui/Icon";
import media from "shared/utils/media";

import SalesforceForm from "./SalesforceForm";
import MeetingPlanList from "./MeetingPlanList";
import MeetingTitle from "./MeetingTitle";
import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import moment from "moment";

const CALL_LAYOUT = {
  CALL_REASON: 0,
  CALL_DETAIL: 1,
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme?.border?.color ||  COLORS.white};
  margin-bottom: ${SPACING.MD}px;
  padding-bottom: ${SPACING.SM}px;
`;

const ConfirmationModal = styled(Modal)`
  width: 428px;
  ${media.lessThan("tablet")`
    width: 100%;
  `}
`;

const Toggle = styled(DropdownToggle)`
  background-color: ${({ theme}) => theme.colors.secondary} !important;
  padding: ${SPACING.XXS}px ${SPACING.XS}px;
  border: 1px solid ${({ theme}) => theme.colors.secondary} !important;
  &:hover,
  &:focus {
    background-color: ${({ theme}) => theme.colors.secondary};
    outline: 0;
    box-shadow: none !important;
  }
`;

const DropdownListItem = styled(DropdownItem)`
  color: ${({ theme}) => theme.text.color };
  &:focus,
  &:hover {
    color: ${({ theme}) => theme.text.color };
    background-color: ${({ theme }) => theme.colors.active};
    outline: 0;
    box-shadow: none !important;
  }
`;

const Menu = styled(DropdownMenu)`
  background-color: ${({theme}) => theme?.card?.contentBackground || ` ${COLORS.chambray}`};
  color: ${({ theme}) => theme.text.color };

`;

const ConfirmButton = styled(Button)`
  background-color: ${({theme}) => theme?.button?.bg?.primary };
`;

const Container = styled.div`
  padding: 30px;
  width: 100%;
  ${media.lessThan("tablet")`
    padding-top:8px;
  `}
`;

const CallLayout = ({ isList, isPrep }) => {
  let { reason, prepareId, playBook } = useParams();
  const prepId = prepareId;
  const { zoom, company } = useSelector((state) => state.content);
  const { claims } = useSelector((state) => state.member);
  const { info } = useSelector((state) => state.users);
  const [callReason, setCallReason] = useState(reason);
  const [view, setView] = useState(CALL_LAYOUT.CALL_REASON);
  const [contentOptions, setContentOptions] = useState([]);
  const [board, setBoard] = useState({});
  const [selectedContentOption, setSelectedContentOption] = useState(null);
  const [showContenOptionsPanel, setShowContenOptionsPanel] = useState(false);
  const [showCallReasonDropDown, setShowCallReasonDropDown] = useState(false);
  const [interaction, setInteraction] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null); //set the intended key to switch to
  const [isSalesforceView, setIsSalesforceView] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [isOutcomeOpen, setIsOutcomeOpen] = useState(false);
  const [outcomeLoading, setOutcomeLoading] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  const { theme } = useAuth();
  // const confirmationModalRef = useRef(null);
  const scrollToRef = useRef();
  let navigate = useNavigate();
  let { interactionId } = useParams();

  const companyId = claims.companyId;
  const [playbook, setPlaybook] = useState(zoom);
  const [callReasons, setCallReasons] = useState(zoom?.content?.Triggers || {});
  const callFlows = company?.[TYPE_LIST.CALLFLOWS] || {};
  const uid = info?.uid;

  
  useEffect(() => {
    if (playBook && callReason) {
      onPlaybookChange(playBook);
      handleCallReasonChange(callReason)
    }
  }, [playBook, callReason]);

  useEffect(() => {
    if (reason) {
      handleCallReasonChange(reason);
    }
    // else if (!isPrep && Object.keys(callReasons)[0]) {
    //   handleCallReasonChange(Object.keys(callReasons)[0]);
    // }
  }, [reason]);

  const onPlaybookChange = (id) => {
    const playbookInfo = company?.[TYPE_LIST.CALLFLOWS]?.[id];
    setPlaybook({ ...playbookInfo, id });
    setCallReasons(playbookInfo?.content?.[TYPE_LIST.TRIGGERS] || {});
  };

  const onAllOptions = (key) => {
    if (
      company?.[TYPE_LIST.TRIGGERS]?.[key]?.active &&
      !company?.[TYPE_LIST.TRIGGERS]?.[key]?.deleted &&
      callReasons[key]
    ) {
      let options = [];
      Object.keys(callReasons[key]).map((type) => {
        const typeOptions = callReasons[key][type];
        return Object.keys(typeOptions)
          .filter(
            (typeId) =>
              company[type]?.[typeId]?.active &&
              !company?.[type]?.[typeId]?.deleted &&
              type !== TYPE_LIST.DISPOSITIONS
          )
          .sort((a, b) =>
            company?.[type]?.[a]?.label > company?.[type]?.[b]?.label
              ? 1
              : company?.[type]?.[a]?.label < company?.[type]?.[b]?.label
              ? -1
              : 0
          )
          .map((typeId) => {
            options.push({
              ...company[type][typeId],
              id: typeId,
              type,
            });
          });
      });
      setAllOptions(options);
    }
  };

  const handleCallReasonSelect = async (key, reset) => {
    setCallReason(key);
    setView(CALL_LAYOUT.CALL_DETAIL);
    const tempBoard = reset
      ? {
          [generatePushID()]: {
            id: key,
            time: isPrep ? false : new Date().toISOString(),
            type: TYPE_LIST.TRIGGERS,
          },
        }
      : {
          ...board,
          [generatePushID()]: {
            id: key,
            time: isPrep ? false : new Date().toISOString(),
            type: TYPE_LIST.TRIGGERS,
          },
        };
    setBoard(tempBoard);
    onAllOptions(key);
  };

  const onAnswerClicked = (
    answer,
    index,
    id,
    type,
    boardKey,
    otherAnswer,
    selectedOptions
  ) => {
    let tempBoard = { ...board };
    const prevResponseKey = Object.keys(tempBoard[boardKey]).find((val) =>
      val.startsWith("response_")
    );
    let unselectedAnswer = false;

    if (prevResponseKey) {
      delete tempBoard[boardKey][prevResponseKey];
      try {
        const prevIndex = prevResponseKey.split("response_")[1];
        if (
          prevIndex === `${index}` ||
          (otherAnswer && prevIndex === "input")
        ) {
          unselectedAnswer = true;
        }
      } catch (error) {}
    }

    let answerDetails = false;
    if (!unselectedAnswer) {
      answerDetails = {
        ...(otherAnswer
          ? { response_input: otherAnswer }
          : { [`response_${index}`]: answer }),
      };
    } else {
      answerDetails = null;
    }

    tempBoard[boardKey] = {
      ...tempBoard[boardKey],
      ...(answerDetails && { ...answerDetails }),
      selectedOptions,
    };

    if (
      company[type][id]?.optionFollowUp &&
      company[type][id].optionFollowUp?.[index]?.length &&
      company[type][id].optionFollowUp[index][0]
    ) {
      const followUpId = company[type][id].optionFollowUp[index][0];
      if (
        company?.[type]?.[followUpId]?.active &&
        !company?.[type]?.[followUpId]?.deleted
      ) {
        tempBoard = {
          ...tempBoard,
          [generatePushID()]: {
            id: followUpId,
            time: isPrep ? false : new Date().toISOString(),
            type,
          },
        };
      }
    }
    setBoard(tempBoard);
  };

  const onDeleteBoardItem = (key) => {
    let tempBoard = { ...board };
    delete tempBoard[key];
    setBoard(tempBoard);
  };

  const handleContenOptionSelect = (val) => {
    setBoard({
      ...board,
      [generatePushID()]: {
        id: val.id,
        time: isPrep ? false : new Date().toISOString(),
        type: val?.type || selectedContentOption,
      },
    });

    scrollToRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

    setShowContenOptionsPanel(false);
  };

  const handleOptionSelect = (option) => {
    if (option === selectedContentOption && showContenOptionsPanel) {
      setShowContenOptionsPanel(false);
      setSelectedContentOption(null);
      setContentOptions([]);
      return;
    }
    let options = [];
    switch (option) {
      case TYPE_LIST.DISCOVERY_QUESTIONS:
      case TYPE_LIST.OBJECTIONS:
      case TYPE_LIST.SOCIALPROOFS:
      case TYPE_LIST.BATTLESCARDS:
      case TYPE_LIST.FAQS:
        if (callReasons[callReason]?.[option]) {
          let allowedContent = Object.keys(
            callReasons[callReason][option]
          ).filter((key) => key !== "active");
          if (allowedContent?.length) {
            for (const key of allowedContent) {
              if (company[option][key]?.active) {
                const contentOption = company[option][key];
                options.push({ id: key, ...contentOption });
              }
            }
          }
        }
        break;
      default:
        break;
    }
    setContentOptions(options);
    setShowContenOptionsPanel(true);
    setSelectedContentOption(option);
  };

  const handleCallReasonChange = async (key) => {
    try {
      // if (Object.keys(board)?.length > 1) {
      //   await confirmationModalRef.current.show();
      // }
      handleCallReasonSelect(key, true);
      setContentOptions([]);
      setShowContenOptionsPanel(false);
      setSelectedContentOption(null);
    } catch (error) {
      return;
    }
  };

  const validateInteraction = async (data) => {
    const isValidInteraction = await validateMeeting(companyId, interactionId);
    if (isPrep) {
      setInteraction({ ...interaction, ...data });
    } else if (!isValidInteraction) {
      navigate("/home");
    } else if (isValidInteraction) {
      if (isValidInteraction.triggerId) {
        setBoard(isValidInteraction.timeline || board);
      }
      setCallReason(
        isValidInteraction.triggerId ||
          (callReasons && Object.keys(callReasons)[0]) ||
          null
      );
      setInteraction(isValidInteraction);
      setAllOptions([]);
      if (isValidInteraction.triggerId) {
        setView(CALL_LAYOUT.CALL_DETAIL);
      } else {
        setView(CALL_LAYOUT.CALL_REASON);
      }
    }
  };

  const updateMeetingInteraction = async () => {
    await updateMeetingTimeline(companyId, interactionId, board, callReason, playbook?.id);
  };

  const onMeetingPrepSave = () => {
    const date = moment(new Date()).format("MM/DD/YYYY ");
    updateMeetingPrepare(
      companyId,
      uid,
      {
        ...interaction,
        topic: interaction?.topic || "Template " + date,
        triggerId: callReason,
        callFlowId: playbook?.id,
      },
      board,
      prepId
    );
    navigate("/saved");
  };

  // const handleContentUpdate = async () => {
  //   const customToken = await getCustomToken(null);
  //   let pathname = "https://test.upmarket.ai"
  //   if (window.location.hostname?.includes("localhost")) {
  //     pathname = "https://localhost:3001"
  //   }
  //   window.open(
  //     `${pathname}/loading?customToken=${customToken}&returnUrl=content?quickAdd=true`
  //   );
  // };

  const handleNotes = (note) => {
    setInteraction({ ...interaction, notes: note });
    if (!isPrep && interactionId) {
      updateMeeting(companyId, interactionId, {
        notes: note,
      });
    }
  };

  const handleTemplate = (template) => {
    delete template.topic;
    setInteraction({ ...interaction, ...template });
    let dateCreated = new Date().toISOString();
    let timeline = {};
    if (template?.timeline) {
      Object.keys(template.timeline).map((t) => {
        timeline = {
          ...timeline,
          [t]: {
            ...template.timeline[t],
            time: dateCreated,
          },
        };
      });
    }
    setBoard(timeline);
    setCallReason(template?.triggerId);
    setPlaybook(company?.[TYPE_LIST.CALLFLOWS]?.[template?.callFlowId]);
    setView(CALL_LAYOUT.CALL_DETAIL);
    onAllOptions(template?.triggerId);
    if (template.notes) {
      updateMeeting(companyId, interactionId, {
        notes: template?.notes,
      });
    }
  };

  useEffect(() => {
    if (isPrep) {
      if (prepId) {
        let prepInfo = info?.zoom?.saved?.[prepId];
        setBoard(prepInfo?.timeline);
        setInteraction(prepInfo);
        if (prepInfo?.triggerId) {
          setCallReason(prepInfo.triggerId);
          setView(CALL_LAYOUT.CALL_DETAIL);
        }
        if (prepInfo?.callFlowId) {
          setPlaybook(company?.[TYPE_LIST.CALLFLOWS]?.[prepInfo?.callFlowId]);
        }
      }
    } else if (!interactionId && !isList) {
      navigate("/home");
    } else if (!isList) {
      validateInteraction();
    }
  }, [interactionId]);

  useEffect(() => {
    if (interaction && !isPrep) {
      updateMeetingInteraction();
    }
  }, [board]);

  const toggle = () => {
    setShowConfirmation(!showConfirmation);
  };

  return (
    <HorizontalAnimationWrapper>
      <Container>
        <Header style={{ width: "100%" }} theme={theme}>
          <FlexContainer
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <FlexContainer
              style={{
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <FlexContainer style={{ width: "85%" }}>
                <span style={{ width: 40, cursor: "pointer", marginTop: -2 }}>
                  <Icon
                    onClick={() => {
                      isPrep && view !== 0
                        ? setView(CALL_LAYOUT.CALL_REASON)
                        : navigate("/");
                    }}
                    type={"caretLeft"}
                    style={{ fontSize: 40, marginRight: SPACING.SM }}
                  />
                </span>
                {interaction ||
                (view === CALL_LAYOUT.CALL_DETAIL && callReason) ? (
                  <MeetingTitle
                    interaction={interaction}
                    validateInteraction={validateInteraction}
                    isPrep={isPrep}
                  />
                ) : (
                  <h2 style={{ fontWeight: "bold" }}>
                    {"Select Meeting Plan"}
                  </h2>
                )}
              </FlexContainer>
              <FlexContainer style={{
                // backgroundColor:'red',
                alignItems: 'center'
              }}>
                {interaction?.dispositionLabel && <Button size="sm" onClick={() => setIsOutcomeOpen(true)} disabled={outcomeLoading}>{interaction?.dispositionLabel}</Button>}
                {view === CALL_LAYOUT.CALL_REASON && <>
                  <Dropdown
                    color={theme.colors.primary}
                    isOpen={showCallReasonDropDown}
                    toggle={() =>
                      setShowCallReasonDropDown(!showCallReasonDropDown)
                    }
                  >
                    <Toggle caret>
                      {playbook.label}
                    </Toggle>
                    <Menu>
                      <p style={{ textDecoration: "underline", color: theme?.text?.color || '#fff', padding: ".25rem 1rem", margin: 0 }}>Playbooks</p>
                      {Object.keys(callFlows)
                        .filter(id => callFlows?.[id]?.active && !callFlows?.[id]?.deleted)
                        .map((key) => (
                          <DropdownListItem
                            key={key}
                            onClick={() => {
                              onPlaybookChange(key)
                            }}
                          >
                            {company?.[TYPE_LIST.CALLFLOWS][key]?.label}
                          </DropdownListItem>
                        ))}
                    </Menu>
                  </Dropdown>
                </>}

                {view === CALL_LAYOUT.CALL_DETAIL && callReason ? (
                  <>
                    <div style={{ paddingRight: 10 }}>
                      <Icon
                        className="modal_header-close"
                        type={ICON_TYPES.close}
                        style={{ fontSize: TYPOGRAPHY.h3 }}
                        onClick={() => {
                          setClearClicked(true);
                          setShowConfirmation(callReason);
                        }}
                      />
                    </div>
                    <Dropdown
                      color={COLORS.primary}
                      isOpen={showCallReasonDropDown}
                      toggle={() =>
                        setShowCallReasonDropDown(!showCallReasonDropDown)
                      }
                    >
                      <Toggle caret>
                        {company.Triggers?.[callReason]?.label || "( Select )"}
                      </Toggle>
                      <Menu>
                        <p
                          style={{
                            textDecoration: "underline",
                            color: theme?.text?.color || '#fff',
                            padding: ".25rem 1rem",
                            margin: 0,
                          }}
                        >
                          Playbook
                        </p>
                        <p
                          style={{
                            color: theme?.text?.color || '#fff',
                            padding: ".25rem 1rem",
                            margin: 0,
                          }}
                        >
                          {playbook.label}
                        </p>
                        <p
                          style={{
                            textDecoration: "underline",
                            color: theme?.text?.color || '#fff',
                            padding: ".25rem 1rem",
                            margin: 0,
                          }}
                        >
                          Meeting Plans
                        </p>
                        {Object.keys(callReasons)
                          .filter(
                            (id) =>
                              company.Triggers?.[id]?.active &&
                              !company.Triggers?.[id]?.deleted
                          )
                          .map((key) => (
                            <DropdownListItem
                              key={key}
                              onClick={() => {
                                setShowConfirmation(key);
                              }}
                            >
                              {company.Triggers[key]?.label}
                            </DropdownListItem>
                          ))}
                      </Menu>
                    </Dropdown>
                  </>
                ) : null}
                {(isPrep && view === CALL_LAYOUT.CALL_DETAIL && callReason) && <Button style={{ marginLeft: SPACING.SM, height: '35px' }} onClick={onMeetingPrepSave}>Save</Button>}
                <ActionsMenu isPrep={isPrep} interactionId={prepId || interactionId} interaction={interaction} handleOutcomeToggle={() => setIsOutcomeOpen(false)} outcomeToggle={isOutcomeOpen} companyId={companyId} onOutcomeLoading={(value)=>{
                  setOutcomeLoading(value)
                }}/>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Header>

        {view === CALL_LAYOUT.CALL_REASON && (
          <MeetingPlanList
            list={Object.keys(callReasons)}
            callFlowId={playbook?.id}
            handleCallReasonSelect={handleCallReasonSelect}
            handleTemplate={handleTemplate}
            isPrep={isPrep}
          />
        )}

        {view === CALL_LAYOUT.CALL_DETAIL && callReason && (
          <>
            {isSalesforceView && (
              <Modal
                isOpen={isSalesforceView}
                toggle={() => setIsSalesforceView(false)}
              >
                <ModalBody style={{ padding: 5 }}>
                  <SalesforceForm
                    reloadInteraction={validateInteraction}
                    uid={uid}
                    companyId={companyId}
                    interactionId={interactionId}
                    interaction={interaction}
                    
                  />
                </ModalBody>
              </Modal>
            )}
            {!isSalesforceView && (
              <div>
                <ScrollContainer
                  style={{
                    display: "flex",
                    height: "calc(100vh - 280px)",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  minHeight={300}
                >
                  <div
                    ref={scrollToRef}
                    style={{ maxWidth: "800px", width: "100%" }}
                  >
                    {board && Object.keys(board)?.length
                      ? Object.keys(board).map((key) => {
                          const contentData = board[key];
                          if (
                            contentData.type === "RecordingStarted" ||
                            contentData.type === "RecordingStopped"
                          ) {
                            return null;
                          }
                          const id = contentData.id;
                          const title =
                            CONTENT_TYPE[contentData.type]?.controlLabel;
                          const type = contentData.type;
                          const content = company?.[type]?.[id];
                          const boardItemContent = board[key];
                          return (
                            <MeetingContentCard
                              key={key}
                              boardKey={key}
                              boardItemContent={boardItemContent}
                              id={id}
                              title={title}
                              type={type}
                              content={content}
                              onDeleteBoardItem={onDeleteBoardItem}
                              onAnswerClicked={onAnswerClicked}
                            />
                          );
                        })
                      : null}
                    <div style={{ paddingTop: 130 }}></div>
                  </div>
                </ScrollContainer>

                {/* </PerfectScrollbar> */}
              </div>
            )}

            <div
              style={{
                bottom: "0",
                position: "absolute",
                margin: "0 auto",
                left: "50%",
                transform: "translate(-50%, 0%)",
                width: "75%",
                maxWidth: 800,
                minWidth: "373px",
              }}
            >
              <MeetingOptionPanel
                options={contentOptions}
                isLive={true}
                onOptionSelect={handleOptionSelect}
                handleContenOptionSelect={handleContenOptionSelect}
                onNoteChange={handleNotes}
                notes={interaction?.notes}
                allOptions={allOptions}
              />
            </div>
          </>
        )}

        <ConfirmationModal isOpen={!!showConfirmation} toggle={toggle}>
          <Icon
            className="modal_header-close"
            type={ICON_TYPES.close}
            style={{
              position: "absolute",
              right: 15,
              top: 10,
              fontSize: TYPOGRAPHY.h3,
            }}
            onClick={toggle}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h4 style={{ paddingBottom: 10, paddingTop: 15 }}>
              Change Conversation Type
            </h4>
            <h5>The conversation will be cleared.</h5>
            <h5>Are you sure you want to continue?</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 20,
                paddingBottom: 15,
              }}
            >
              <ConfirmButton
                onClick={() => {
                  handleCallReasonChange(showConfirmation);
                  setShowConfirmation(null);
                  if (clearClicked) {
                    setView(CALL_LAYOUT.CALL_REASON);
                    setClearClicked(false);
                  }
                }}
              >
                Confirm
              </ConfirmButton>

              {/* <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                setShowConfirmation(null)
              }}
            >Cancel</Button> */}
            </div>
          </div>
        </ConfirmationModal>
      </Container>
    </HorizontalAnimationWrapper>
  );
};

export default CallLayout;
