export const initialState = {
  users: {},
  errors: {}
};

export default function memberReducer(state = initialState, action) {
  switch (action.type) {

    case 'UPDATING_USER_AUTO_JOIN':{
      return {
        ...state,
        isUpdatingAutoJoin: action.data
      }
    }

    case 'USER_PUBLIC_DATA':{
      return {
        ...state,
        info: {
          ...action.data
        }
      }
    }
    case 'USER_COMPANY_SUBSCRIPTION': {
      return {
        ...state,
        subscription: {
          ...action.data
        }
      }
    }
    case 'USERS_DATA':{
      return {
        ...state,
        users: {
          ...action.data
        }
      }
    }
    case 'USER_LOGIN_STATUS':{
      return {
        ...state,
        isLoggedIn: action.data
      }
    }

    case 'USER_ORG_DATA': {
      return {
        ...state,
        org: {
          ...action.data
        }
      }
    }

    case 'USER_TOKEN_ERROR': {
      return {
        ...state, 
        errors: action.data
      }
    }

    // case 'USER_LOGOUT': {
    //   return{
    //     info: {},
    //     errors: {},
    //     org: {},
    //     isLoggedIn: false
    //   }
    // }

    default:
      return state;
  }
}