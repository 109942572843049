import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Modal } from "../../shared/ui/Modals";
import { Button } from "shared/ui/Buttons";
import { Card } from "shared/ui/Cards";
import { Icon } from "shared/ui";
import { ControlledTooltip, ToolTipItem } from "shared/ui/ToolTips";
import { APP_LAYOUT, COLORS, SPACING } from "../../shared/constants";
import { Avatar } from "shared/ui/Icon";
import TableChartModal from "./TableChartModal";
import { hideTeamFeedType } from "../../redux/actions/content";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../redux/actions/meetings";
// import { COLORS } from "shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import DateFilter from "shared/ui/DateFilter";

const HorizontalScrollView = styled.div`
  overflow: scroll;
  position: relative;
  display: flex;
  // ${({isFullHeight}) => isFullHeight ? "height: 165px;" : "height: 165px;" }
  height: 180px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.type === APP_LAYOUT.ZOOM ? COLORS.greyDark :  theme.colors.grey1};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const Content = styled.div`
  position: absolute;
  display: flex;
`;

const StatisticCard = styled(Card)`
  ${({data}) => data && `cursor: pointer;`}
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 165px;
  width: 270px;
  border-radius: 5px;
  margin-top: 0px;
  color: ${COLORS.white};
  background-color: ${({theme}) => theme.card.active || COLORS.primary};
`;

export default function Featured(props) {
  const [featuredList, setFeaturedList] = useState([]);
  const [featuredFeedList, setFeaturedFeedList] = useState([])
  const [selectedItem, setSelectedItem] = useState(null);
  const { new: newContent } = useSelector(state => state?.content || {});
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const {theme} = useAuth()
  const [showModal, setShowModal] = useState(false);
  const [cardData, setCardData] = useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hiddenTeamFeedTypes = useSelector(
    (state) => state.content.hiddenTeamFeedTypes
  );

  const toggle = (data = null) => {
    setShowModal(!showModal);
    setCardData(data)
  };

  const onSelect = (data) => {
    if(data.interactions){
      toggle(data)
    }
  }

  const handleDateChange = async (duration) => {
    getDashboardData(
      duration,
      setDashboardLoading,
      (teamFeed, featuredFeed) => {
        setFeaturedFeedList([...teamFeed, ...featuredFeed])
      },
      (error) => {}
    );
  };

  const iconMap = {
    meeting: {
      iconName: "fire",
    },
    feed_team_successful_user: {
      iconName: "trendUp",
    },
    feed_feature_rising: {
      iconName: "trendUp",
    },
    feed_team_most_interactions: {
      iconName: "rocket",
    },
    feed_team_breakout: {
      iconName: "filledStar",
    },
    feed_team_successful_content: {
      iconName: "crown",
    },
    feed_feature_content_combo: {
      iconName: "balloons",
    },
    // new: {
    //   iconName: "sparkles",
    // },
    feature_not_successful: {
      iconName: "exclamation",
    },
  };

  useEffect(() => {
    let tempActivityList = [];
    if(featuredFeedList?.length) {
      tempActivityList = [...featuredFeedList];
    }
    if(newContent && Object.keys(newContent)){
      Object.keys(newContent).map(key => {
        const contentParent = newContent[key];
        if(contentParent && Object.keys(contentParent).length) {
          Object.keys(contentParent).map(childId => {
            const childContent = contentParent?.[childId] || {};
            tempActivityList.push({
              ...childContent,
              label: `New ${key} ${childContent?.label}`,
              type: key,
              id: childId,
              cardType: "New"
            })
          })
        }
      })
    }
    if(tempActivityList?.length) {
      setFeaturedList(tempActivityList)
    }
  }, [newContent, featuredFeedList])

  useEffect(() => {
  }, [])

  // const featureFeed = featuredList.filter(
  //   (element) => !hiddenTeamFeedTypes[element.filter]
  // );
  const featureFeed = []
  return (
    <SkeletonTheme
      baseColor={COLORS.lightBlue}
      highlightColor={COLORS.grey1}
      borderRadius="0.5rem"
      duration={0.5}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DateFilter  onDateChange={handleDateChange} theme={theme} title={"Featured"} />
        <HorizontalScrollView isFullHeight={(featureFeed.length && !dashboardLoading )|| dashboardLoading }>
          <Content>
            {featuredList.length && !dashboardLoading
              ? featuredList.map((item, index) => {
                  return (
                    <StatisticCard
                      key={index}
                      index={index}
                      data={item?.interactions}
                      onClick={() => onSelect(item)}
                    >
                      <ToolTipItem
                        label={item.label}
                        direction="top"
                        itemId={`Statistics${index}`}
                        showToolTip={true}
                      >
                        <h4
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Icon
                            type={iconMap[item.filter]?.iconName}
                            style={{ paddingRight: 5 }}
                          />
                          {item.label}
                        </h4>
                      </ToolTipItem>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        {item.text}
                      </div>
                      <div style={{ display: "flex" }}>
                       {/* {((item.callFlowId && item.triggerId))
                       && <Button
                          type="button"
                          color="primary"
                          style={{
                            height: "40px",
                          }}
                          // color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/prepare/playBook/${item.callFlowId}/meetingPlan/${item.triggerId}`)
                          }}
                        >
                          Try It Now
                        </Button>} */}
                        <Button
                          type="button"
                          color="primary"
                          style={{
                            height: "40px",
                            backgroundColor: COLORS.transparent,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(hideTeamFeedType(item));
                          }}
                        >
                          Hide
                        </Button>
                      </div>
                    </StatisticCard>
                  );
                })
              : null}
            {dashboardLoading
              ? [{}, {}, {}].map((index, i) => {
                  return (
                    <StatisticCard key={i} index={i}>
                      <Skeleton count={1} height="15px" width={"60%"} />
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        <Skeleton count={1} height={"35px"} width={"150px"} />
                      </div>

                    </StatisticCard>
                  );
                })
              : null}
            {!dashboardLoading && !featureFeed.length <=0 ? (
              <div>Featured feed is empty</div>
            ) : null}
          </Content>
          <TableChartModal isOpen={showModal} toggle={toggle} data={cardData || {}} />
        </HorizontalScrollView>
      </div>
    </SkeletonTheme>
  );
}
