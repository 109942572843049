import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';
import { motion } from "framer-motion";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useLocation } from 'react-router-dom'

import InviteMembersModal from "../invite/InviteMembersModal";
import { APP_LAYOUT, BORDER, SPACING } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { getConnection } from "../../redux/actions/connections";
import PasswordModal from "../auth/PasswordModal";
import { BodyContainer } from "../../shared/ui/Containers";
import media from 'shared/utils/media';
import HeaderMenu from "./HeaderMenu";

import SearchImage from "assets/dashboard/Search.png";
import LogoImage from "assets/dashboard/Logo.png";
import Back from "assets/backArrow.png";
import { COLORS } from "shared/constants";
import { Icon } from "shared/ui/Icon";


const Logo = styled.img`
  object-fit: contain;
  cursor : pointer;
  height: 65px;
  width: auto;
  ${media.lessThan('tablet')`

  // height:12vw;
  // width:12vw;
`}
`


const SubscriptionBar = styled.div`
  background-color:${COLORS.azureRadiance};
  display:flex;
  height:33px;
  justify-content:center;
  padding:7px;
  cursor: pointer;
  zIndex:100;
`

const SearchInput = styled.input`
-webkit-appearance: none;
  display: flex;
  flex: 1;
  background-color: ${COLORS.transparent};
  outline: none;
  border:none;
  padding:10px;
  color:${COLORS.white};
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${COLORS.white};
  opacity: 1; /* Firefox */
}
`

const SearchBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0px 20px;

  border-bottom: 3px solid;
  padding:0px 0px;
  // outline:solid;
  // outline-width:2px;
  // outline-color:${COLORS.white}
`

const SearchListItem = styled.h4`
  cursor:pointer;
  padding-left: ${27 + 70 + 25}px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin:0px;
  :hover{
    background-color:${COLORS.azureRadiance}
  }
`

const SearchUnderlineWrapper = styled.div`
    cursor : pointer;
    position:relative;
    padding:5;
    flex-direction: row;
    display: flex;
    flex: 1;
    alignItems: center;
    border-bottom: 2px solid ${COLORS.white};
    margin-right:15px;
    margin-left:10px;
    opacity:0.3
`
const mockData = ['Jason Tissera <> Flexport', 'Introduction', 'Demo', 'Tiffany <> Operatix']

const Header = () => {
  const location = useLocation();

  const content = useSelector((state) => state.content);
  const users = useSelector((state) => state.users);

  const inputRef = useRef();
  const dispatch = useDispatch();
  const { logout, currentUser, theme } = useAuth();
  const { info, isLoggedIn } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);


  const { uid, companyId } = info || {};
  const { header } = content?.display || {};
  const shouldResetPassword = users?.info?.shouldResetPassword || false;
  const shouldAuthorizeZoom = users?.info?.shouldAuthorizeZoom || false;

  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal)
  }

  const handleLogout = async () => {
    await logout();
    dispatch({ type: "USER_LOGOUT" });
    navigate("/");
  };

  const authorizeZoom = async () => {
    const connection = await getConnection();
    const oauth2Endpoint = "https://zoom.us/oauth/authorize";

    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", oauth2Endpoint);

    const params = {
      client_id: connection.client_id,
      redirect_uri: connection.callback_url,
      response_type: "code",
      state: "invite",
    };

    for (const p in params) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    const inviteState = localStorage.getItem("inviteState");
    if ((theme?.type !== APP_LAYOUT.UPMARKET) && shouldAuthorizeZoom && inviteState !== "invited" && uid && companyId && currentUser) {
      authorizeZoom();
      localStorage.setItem("inviteState", "invited");
    }
  }, [shouldAuthorizeZoom]);

  // const subMenuAnimate = {
  //   enter: {
  //     opacity: 1,
  //     rotateX: 0,
  //     transition: {
  //       duration: 0.5
  //     },
  //     display: "block"
  //   },
  //   exit: {
  //     opacity: 0,
  //     rotateX: -15,
  //     transition: {
  //       duration: 0.5,
  //       delay: 0.3
  //     },
  //     transitionEnd: {
  //       display: "none"
  //     }
  //   }
  // };

  useEffect(() => {
    // if (searchTerm && searchTerm.length > 3) {
    const searchResults = mockData.filter((option) => option.includes(searchTerm))
    setSearchResult(searchResults)
    // }
  }, [searchTerm])

  const showSubscription = location?.pathname.includes('/meeting/') || location?.pathname.includes('prepare');
  if(!currentUser){
    return <></>
  }
  return (
    <div>
      <InviteMembersModal
        toggle={toggleInviteModal}
        isOpen={showInviteModal}
      />
      {/* {currentUser && !showSubscription && (
        <SubscriptionBar onClick={toggleInviteModal}>
          <h4 style={{}}>Get a free month of Upmarket!</h4>
        </SubscriptionBar>)} */}
      <div
        style={{
          backgroundColor: 'transparent',
          height: 60,
          display: "flex",
          flexDirection: 'row',
          alignItems: 'center',
          padding: 30,
        }}
      >
        <PasswordModal isOpen={shouldResetPassword && isLoggedIn} />

        {currentUser && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: `${SPACING.SM}px 0`,
            }}
          >

            <Logo
              onClick={() => {
                navigate("/home");
              }}
              src={LogoImage}
            />
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: "flex-end" // remove when adding in search

            }}>
              {/* <SearchUnderlineWrapper
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <h4>
                  Search 
                </h4>
              </SearchUnderlineWrapper> */}
              <HeaderMenu info={info} handleLogout={handleLogout} toggleInviteModal={toggleInviteModal} />
            </div>

            <Modal
              unmountOnClose={true}
              isOpen={open}
              toggle={() => {
                setOpen(!open)
              }}
              style={{
                width: '100vw',
                maxWidth: '100vw',
                minHeight: '25vh',
                backgroundColor: COLORS.mulledArrack,
                margin: 0
              }}
              backdrop={true}
              animation={true}
              onOpened={() => {
                inputRef.current.focus()
              }}
            >
              <div
                style={{ position: 'absolute', width: '100%', left: 0, backgroundColor: COLORS.mulledArrack, paddingBottom: 20 }}
              >
                <div
                  style={{
                    zIndex: 1,
                    height: 80,
                    width: '100%',
                    margin: 0,
                    outlineWidth: 0,
                    display: "flex",
                    alignItems: "center",
                    padding: `${SPACING.SM}px 30px`,
                  }}
                >
                  <Logo
                    src={LogoImage}
                  />
                  <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>

                    <SearchBar>
                      <SearchInput
                        ref={inputRef}
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(event) => {
                          setSearchTerm(event.target.value)
                        }}
                      />
                     
                      <Icon type="close"
                        onClick={() => {
                          setOpen(false)
                        }}
                        alt="back"
                        style={{
                          cursor: 'pointer',
                          objectFit: 'contain',
                          height: 20,
                          width: 14,
                          fontSize: 20
                        }} />
                    </SearchBar>
                    <div onClick={() => {
                      setOpen(false)
                    }}>
                      <HeaderMenu info={info} handleLogout={handleLogout} disable={true} toggleInviteModal={toggleInviteModal} />
                    </div>
                  </div>

                </div>

                {searchResult.map((option, i) => {
                  return (<SearchListItem key={i} onClick={() => {
                    setOpen(false)
                  }}>{option}</SearchListItem>)
                })}
              </div>
            </Modal>

          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
