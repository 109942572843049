import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Slider from "rc-slider";
import moment from "moment";
import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import { Helmet } from "react-helmet";

import {
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  SCREEN_SIZES,
  APP_LAYOUT,
} from "../../shared/constants";
import {
  getInteraction,
  getPlayDetails,
  updateMeeting,
} from "../../redux/actions/meetings";
import { toast } from "react-toastify";
import MeetingOptionPanel from "./MeetingOptionPanel";
import MeetingPlaybackContentCard from "./MeetingPlaybackContentCard";
import ParticipantModal from "./ParticipantModal";
import ReactPlayer from "react-player";
import { Loader } from "shared/ui";
import { Button } from "../../shared/ui/Buttons";
import { Avatar, Icon } from "../../shared/ui/Icon";
import styled from "styled-components";
import MeetingTitle from "./MeetingTitle";
import VideoPoster from "../../media/Images/video_placeholder.png";

import { Modal, ModalBody, PopOver } from "../../shared/ui/Modals";
import { CenterContainer, FlexContainer } from "../../shared/ui/Containers";
import ContactView from "../contact/ContactView";
import MeetingStats from "./MeetingStats";
import useWindowDimensions from "../../shared/utils/hooks";
import Stopwatch from "./StopWatch";
import media from "shared/utils/media";

import ActionsMenu from "./menus/ActionsMenu";
import { useAuth } from "../../contexts/AuthContext";
import MeetingComments from "./MeetingComments";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.MD}px;
  padding-bottom: ${SPACING.SM}px;
  border-bottom: 1px solid ${({theme}) => theme?.border?.color ||  COLORS.white};
`;

const Details = styled.div`
  width: 85%;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled.div`
  position: absolute;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
`;

const ScrollWrapper = styled.div`
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: ${SPACING.SM};
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.type === APP_LAYOUT.ZOOM ?  COLORS.greyDark :  theme?.colors?.grey1 ||  COLORS.greyDark };
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: ${({width}) => width || '80%' };
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 90%;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  //  background-color:green;
`;
const TimeDurationWrapper = styled.div`
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 150px;
  max-width: 250px;
  bottom: 10px;
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 5px;
  padding-top: 10px;
  background-color: rgba(1, 1, 1, 0.4);
  border-radius: 10px;
  display: ${({isRunning}) => isRunning ? "none" : "block"} ;
  transition: .25s;
  text-align: center;
  bottom: 14px;
  color: ${COLORS.white};

`;
const VideoPlaceholder = styled.div`
  position: relative;
  display: grid;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-top: 56.25%;
  object-fit: cover;
  &:hover {
    ${TimeDurationWrapper} {
      display: block
    }
  }
`;

const PlaceholderImage = styled.img`
  height: auto;
  width: 100%;
  // object-fit: contain;
  // grid-column: 1;
  // grid-row: 1;
  position: absolute;
  top: 0;

`;

const VideoControls = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.25s ease-out;
  opacity: ${(props) => (props.running ? 0 : 1)};
  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  height: 100%;
  ${media.lessThan("tablet")`
    // flex-direction: column;
    // padding-top:8px;
  `}
`;

const LinerGradientContainer = styled(CenterContainer)`
  width: 100%;
`;

const StatisticsTimelineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // background-color:red;
  // flex: ${(props) => (props.showStats || props.showTranscript ? 1 : 0)};
  flex: 1;
  width: 100%;
  min-height: 180px;
`;

const VideoPlayerContainer = styled(ReactPlayer)`
  video {
    // width: 100% !important;
    background-color: black;
  }
  // width: 100% !important;
  // height: 40vh;
`;


const NoRecordingsWrapper = styled.div`
  position: absolute;
  bottom: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 35%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;
  padding-top: 10px;
  background-color: rgba(1, 1, 1, 0.4);
  border-radius: 10px;
`;

const MeetingPlayback = () => {
  const { company } = useSelector((state) => state.content);
  const { info } = useSelector((state) => state.users);
  const { claims } = useSelector((state) => state.users);
  const { theme } = useAuth()
  const [interaction, setInteraction] = useState(null);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [filteredTimeline, setFilteredTimeline] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [marks, setMarks] = useState({});
  const [playUrl, setPlayUrl] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [selectedContentOption, setSelectedContentOption] = useState("meeting");
  const filterRef = useRef(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [node, setNode] = useState(null);
  let { interactionId, accessCode } = useParams();
  const [selectedContact, setSelectedContact] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [running, setRunning] = useState(false);
  const [seek, setSeek] = useState(0);
  const [isOutcomeOpen, setIsOutcomeOpen] = useState(false);
  const [outcomeLoading, setOutcomeLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const location = useLocation();
  const { width, height } = useWindowDimensions();
  let { uid, companyId } = info || {};
  const isAdmin = claims?.role === "Admin";
  const getMarks = (intr) => {
    if (intr?.events?.marks) {
      let tempMarks = intr.events.marks;
      Object.keys(tempMarks).map((key) => {
        const mark = tempMarks[key];
        let markUi = null;
        if (mark?.length) {
          markUi = mark
          .filter(timelineKey => intr.timeline[timelineKey]?.type !== "entity")
          .map((timelineKey) => {
            return (
              <div>
                <Avatar
                  name={intr.timeline[timelineKey]?.type}
                  size="20"
                  round
                />
              </div>
            );
          });
          tempMarks[key] = markUi[0];
        }
      });
      setMarks(tempMarks);
    }
  };

  const handleAssignSingleContact = (participants) => {
    console.log("handleAssignSingleContact");
  };

  const validateInteraction = async (silentRefresh = false) => {
    if (!silentRefresh) {
      setDownloadingFile(true);
    }
    setCurrentTime(0);
    let isValidInteraction = null
    try{
      isValidInteraction = await getInteraction({companyId, interactionId, uid, isAdmin});
    }catch(e){
      navigate("/home");
    }
    if (!isValidInteraction || isValidInteraction?.error) {
      if (isValidInteraction?.error) {
        toast(isValidInteraction?.error.message, { hideProgressBar: true });
      } else {
        navigate("/home");
      }
    } else {
      dispatch({
        type: "UPDATE_MEETING_INTETACTIONS",
        data: { value: isValidInteraction, id: interactionId },
      });
      if (isValidInteraction.participants) {
        // setParticipants(isValidInteraction.participants);
        handleAssignSingleContact(isValidInteraction.participants);
      }
      console.log(isValidInteraction, 'VALIDATION')
      getMarks(isValidInteraction);
      if(isValidInteraction.playbackFiles) {
        const recordingId = Object.keys(isValidInteraction.playbackFiles)[0];
        setPlayUrl(isValidInteraction.playbackFiles[recordingId]?.playbackUrl);
      }
      setInteraction(isValidInteraction);
      setDownloadingFile(false);
    }
  };

  const validateMeeting = async () => {
    setDownloadingFile(true);
    const isValidAccessCode = await getPlayDetails(accessCode);
    if (
      isValidAccessCode &&
      isValidAccessCode?.meeting?.companyId &&
      isValidAccessCode?.meeting?.interactionId
    ) {
      companyId = isValidAccessCode.meeting.companyId;
      const intr = await getInteraction({
        companyId,
        interactionId: isValidAccessCode.meeting.interactionId,
        accessCode,
        isAdmin,
        uid
      });
      uid = intr.uid;
      setInteraction(intr);
      getMarks(intr);
      if(intr.playbackFiles) {
        const recordingId = Object.keys(intr.playbackFiles)[0];
        setPlayUrl(intr.playbackFiles[recordingId]?.playbackUrl);
      }
      dispatch({ type: "COMPANY_CONTENT", data: isValidAccessCode.content });
    } else {
      toast("Invalid or Expired token", {
        type: "error",
        hideProgressBar: true,
      });
      navigate("/");
    }
    setDownloadingFile(false);
  };

  const handleOptionSelect = (val, isTranscript = false) => {
    setShowTranscript(isTranscript);
    if (val === TYPE_LIST.STATS) {
      setShowStats(true);
      handlePlayVideo();
    } else {
      setShowStats(false);
      if (isTranscript ||
        ( ((!isTranscript) && interaction?.events?.events[val]?.length) &&
        val !== filterRef.current) 
      ) {
        setFilteredTimeline(interaction.events.events[val]);
        filterRef.current = val;
      } else {
        setFilteredTimeline([]);
        filterRef.current = null;
      }
      setSelectedContentOption(val);
    }
  };

  const handleCardClick = (id) => {
    // if (node && interaction?.timeline[id]) {
    //   const event = interaction?.timeline[id];
    //   node.seek(event.start_time);
    //   setCurrentTime(event.start_time.toFixed(0));
    // }
  };

  const handleSeek = async (value) => {
    if (node) {
      const currentTime = moment(interaction.start_time).add(value, "seconds");
      const videoRecording = interaction?.playbackFiles
        ? Object.keys(interaction?.playbackFiles)?.find((recording) => {
            const interactionRecordings = interaction?.playbackFiles[recording];
            if (interactionRecordings) {
              const startTime = moment(interactionRecordings?.recording_start);
              const endTime = moment(interactionRecordings?.recording_end);
              return currentTime.isBetween(startTime, endTime);
            }
          })
        : false;
      setSeek(value);
      if (interaction?.playbackFiles?.[videoRecording]) {
        setPlayUrl(interaction?.playbackFiles?.[videoRecording]?.playbackUrl);
        // node.seekTo(seek, "seconds");
      } else {
        // setRunning(false);
      }
    }
    setCurrentTime(value);
  };

  const timeElapsed = interaction
    ? moment.utc(currentTime * 1000).format("HH:mm:ss")
    : null;
  const videoLength = interaction
    ? moment.utc(interaction?.duration * 1000).format("HH:mm:ss") !==
      "Invalid date"
      ? moment.utc(interaction?.duration * 1000).format("HH:mm:ss")
      : null
    : null;

  const videoCallbackRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const onParticipantChange = (contact) => {
    setSelectedContact(contact);
    setIsContactOpen(!isContactOpen);
  };
  const handlePlayVideo = (isPlay = false) => {
    // if(isPlay){
    //   node.play();
    // }else{
    //   node.pause();
    // }
  };

  const handlePlay = () => {
    setRunning(true);
  };

  const handlePause = () => {
    setRunning(false);
  };

  const onTimeChange = (time) => {
    setCurrentTime(time);
    if (interaction?.events?.timeline && interaction.events.timeline[time]) {
      const filteredEventList = [];
      interaction.events.timeline[time].map((event)=>{
        if (interaction.timeline[event].processed !== "true") {
          filteredEventList.push(event)
        }
      })
      if (filteredEventList && filteredEventList.length > 0) {
        setFilteredTimeline(filteredEventList);
        const eventStartKey = filteredEventList.find(
          (eventKey) =>
            interaction.timeline[eventKey].type === TYPE_LIST.RECORDING_STARTED
        );
        const eventStoppedKey = filteredEventList.find(
          (eventKey) =>
            interaction.timeline[eventKey].type === TYPE_LIST.RECORDING_STOPPED
        );
        if (eventStoppedKey) {
          setPlayUrl(null);
        }
        if (eventStartKey) {
          const timelineRecord = interaction.timeline[eventStartKey];
          // onVideoLoad(false);
          setPlayUrl(timelineRecord?.playbackUrl);
        }
      }
    }
  };

  useEffect(() => {
    if (interactionId) {
      validateInteraction();
    } else if (!accessCode) {
      navigate("/home");
    }
  }, [interactionId]);

  useEffect(() => {
    if (accessCode) {
      validateMeeting();
    } else if (!interactionId) {
      navigate("/home");
    }
  }, [accessCode]);

  useEffect(() => {
    if (playUrl && seek) {
      node.seekTo(seek, "seconds");
    }
  }, [seek, playUrl]);


  useEffect(() => {
    dispatch({ type: "DISPLAY_CONTENT", data: { header: "Past Meeting" } });
  }, []);

  const handleNotes = (note) => {
    console.log(note,' note -----')
    setInteraction({ ...interaction, notes: note });
    if (interactionId ) {
      updateMeeting(companyId, interactionId, {
        notes: note,
      });
    }
  };

  const renderHeader = () => {
    return (
      <Header theme={theme}>
        <Details isVideoOpen={!showStats && playUrl}>
          <FlexContainer style={{ alignItems: "center", width: "80%" }}>
            {!accessCode && (
              <span style={{ width: 40, cursor: "pointer" }}>
                <Icon
                  onClick={() => {
                    navigate("/history");
                  }}
                  type={"caretLeft"}
                  style={{ fontSize: 40, marginRight: SPACING.SM }}
                />
              </span>
            )}
            <MeetingTitle
              interaction={interaction}
              validateInteraction={validateInteraction}
            />
          </FlexContainer>
        </Details>
        <Actions>
          {interaction?.dispositionLabel && (
            <Button
              size="sm"
              style={{ whiteSpace: "nowrap" }}
              disabled={outcomeLoading}
              onClick={() => !accessCode && setIsOutcomeOpen(true)}
            >
              {interaction?.dispositionLabel}
            </Button>
          )}
          <div
            id="meeting_participants"
            style={{ cursor: "pointer", padding: "0 5px", display: "flex" }}
          >
            {interaction?.participants && (
              <>
                {Object.keys(interaction.participants).length < 3 ? (
                  Object.keys(interaction.participants).map((val, index) => {
                    const participant = interaction?.participants[val];
                    return (
                      <Avatar
                        key={val}
                        name={participant.user_name}
                        round
                        size="25"
                        index={index}
                        style={{ marginRight: -(SPACING.XS + 2) }}
                      />
                    );
                  })
                ) : (
                  <>
                    {Object.keys(interaction.participants)
                      .slice(0, 2)
                      .map((val, index) => {
                        const participant = interaction?.participants[val];
                        return (
                          <Avatar
                            key={val}
                            index={index}
                            name={participant.user_name}
                            round
                            size="25"
                            style={{ marginRight: -(SPACING.XS + 2) }}
                          />
                        );
                      })}
                    <Avatar
                      name={"+"}
                      round
                      size="25"
                      index={2}
                      style={{ marginRight: -(SPACING.XS + 2) }}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <PopOver
            placement="bottom"
            target="meeting_participants"
            trigger="legacy"
            // style={{ width: 250 }}
          >
            <ParticipantModal
              participants={interaction?.participants}
              onParticipantChange={onParticipantChange}
            />
          </PopOver>
          <Modal
            isOpen={isContactOpen}
            toggle={() => {
              onParticipantChange(false);
            }}
          >
            <ModalBody>
              {selectedContact && (
                <ContactView
                  {...selectedContact}
                  modalToggle={() => onParticipantChange(false)}
                />
              )}
            </ModalBody>
          </Modal>
          {!accessCode && (
            <ActionsMenu
              refresh={() => {
                validateInteraction(true);
              }}
              uid={uid}
              companyId={companyId}
              interactionId={interactionId}
              interaction={interaction}
              reloadInteraction={validateInteraction}
              handleOutcomeToggle={() => setIsOutcomeOpen(false)}
              outcomeToggle={isOutcomeOpen}
              isShared={accessCode}
              onOutcomeLoading={(value) => {
                setOutcomeLoading(value);
              }}
            />
          )}
        </Actions>
      </Header>
    );
  };

  const renderVideo = () => {
    return (
      <VideoContainer width={width/height < 1.55? '100%' : "75%"}>
        <VideoPlaceholder show={!running || !playUrl}>
          <PlaceholderImage
            src={VideoPoster}
            alt="Placeholder"
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
          >
            <VideoPlayerContainer
              ref={videoCallbackRef}
              playing={running}
              className="react-player"
              url={playUrl}
              onBuffer={() => {
                setVideoLoading(true);
              }}
              onReady={() => {setVideoLoading(false)}}
              width="100%"
              height="100%"
              style={{
                alignItems: "center"
              }}
            />
          </div>

          {!showStats && interaction?.recordings && videoLength && (
            <VideoControls
              running={running}
              onClick={() => (running ? handlePause() : handlePlay())}
            >
              <Stopwatch
                loading={videoLoading}
                running={running}
                onStart={() => {
                  setRunning(true);
                }}
                onStop={() => {
                  setRunning(false);
                }}
                onTimeChange={onTimeChange}
                time={currentTime}
                setTime={setCurrentTime}
                totalDuration={interaction?.duration}
              />
            </VideoControls>
          )}

          {!interaction?.recordings || !videoLength ? (
            <NoRecordingsWrapper>
              <h4 style={{}}>No recording available</h4>
            </NoRecordingsWrapper>
          ) : null}
          {videoLength && interaction?.recordings && (
            <TimeDurationWrapper isRunning={running}>
              <h5 style={{}}>
                {timeElapsed} / {videoLength}
              </h5>
            </TimeDurationWrapper>
          )}
        </VideoPlaceholder>

        {!showStats && interaction?.recordings && videoLength ? (
          <div
            style={{
              width: "100%",
              height: 50,
            }}
          >
            <Slider
              min={0}
              marks={marks}
              step={1}
              onChange={handleSeek}
              max={interaction.duration}
              value={currentTime}
            />
          </div>
        ) : null}
      </VideoContainer>
    );
  };


  const renderStatisticsOrTimeline = () => {
    return (
      <StatisticsTimelineWrapper
        showStats={showStats}
        showTranscript={showTranscript}
      >
        <ScrollWrapper>
          <Content>
            {showStats ? (
              <MeetingStats
                videoLength={videoLength}
                interaction={interaction}
                outerHeight={
                  width < SCREEN_SIZES.md
                    ? "calc(100vh - 620px)"
                    : "calc(98vh - 270px)"
                }
              />
            ) : filteredTimeline?.length ? (
              filteredTimeline?.map((key) => {
                const contentData = interaction?.timeline[key];
                const type = contentData.type;

                if (
                  type === "entity" ||
                  type === TYPE_LIST.RECORDING_STARTED ||
                  type === TYPE_LIST.RECORDING_STOPPED
                ) {
                  return null;
                }

                const id = contentData.id;
                const title = CONTENT_TYPE[contentData.type]?.controlLabel;
                const content =
                  type === TYPE_LIST.SENTENCE ? contentData : company[type][id];
                const boardItemContent = interaction?.timeline[key];
                let participant = null;
                let index_participant = null
                let time = moment
                  .utc(contentData.start_time * 1000)
                  .format("mm:ss");
                  if (
                  type === TYPE_LIST.SENTENCE &&
                  contentData.participant) {
                  participant =
                    interaction.participants[contentData.participant];
                    Object.keys(interaction.participants).find((p,i )=> p === contentData.participant ?  index_participant = i : null  )
                }
                return (
                  <MeetingPlaybackContentCard
                    key={key}
                    boardKey={key}
                    boardItemContent={boardItemContent}
                    id={key}
                    title={title}
                    type={type}
                    content={content}
                    timelineItem={contentData}
                    participant={participant}
                    onCardClick={handleCardClick}
                    time={time}
                    index={index_participant || 0}
                  />
                );
              })
            ) : null}
            {/* {currentTime === 0 &&
              !running &&
              selectedContentOption === "meeting" &&
              !showStats &&
              interaction?.recordings &&
              videoLength && (
                <h4
                  style={{
                    width: "100%",
                    marginTop: SPACING.XLG,
                    textAlign: "center",
                  }}
                >
                  The transcript will appear here...
                </h4>
              )} */}
          </Content>
        </ScrollWrapper>
        {interaction && (
          <LinerGradientContainer>
            <MeetingOptionPanel
              hideActions={true}
              interaction={interaction}
              onOptionSelect={handleOptionSelect}
              type={showStats ? TYPE_LIST.STATS : selectedContentOption}
              notes={interaction?.notes}
              onNoteChange={handleNotes}
            />
          </LinerGradientContainer>
        )}
      </StatisticsTimelineWrapper>
    );
  };

  const renderMeetingBody = () => {
    if (interaction) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div style={accessCode ? { display: "block"} : { display: "grid", gridTemplateColumns: "1fr 450px" , height: '100%'}}>
            <div style={{padding: 30}}>
              {renderHeader()}
              <ContentWrapper>
                {!showStats &&
                  selectedContentOption !== TYPE_LIST.SENTENCE &&
                  renderVideo()}
                {renderStatisticsOrTimeline()}
              </ContentWrapper>
            </div>
            {!accessCode &&
            <MeetingComments 
              interactionId={interactionId} 
              onNoteChange={handleNotes} 
              notes={interaction?.notes}
              accessCode={accessCode}
              isOwner={interaction?.uid === uid}
              noteOwnerId={interaction?.uid}
              dateCreated={interaction?.start_time}
            />}
          </div>
        </div>
      );
    }
  };

  let meetingLabel = interaction?.topic;

  return (
    <HorizontalAnimationWrapper>
      {location?.pathname.includes("play") && (
        <Helmet>
          <title>{meetingLabel}</title>
          {/* <meta name="image" content={} /> */}
        </Helmet>
      )}

      <MainContainer>
        {downloadingFile ? (
          <Loader />
        ) : (
          <Container>{renderMeetingBody()}</Container>
        )}
      </MainContainer>
    </HorizontalAnimationWrapper>
  );
};

export default MeetingPlayback;
