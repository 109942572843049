import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Tooltip } from "reactstrap";
import { InputForm } from "../../shared/ui/Inputs";

import styled from "styled-components";
import {
  APP_LAYOUT,
  BORDER,
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  TYPOGRAPHY,
} from "../../shared/constants";
import { Button } from "../../shared/ui/Buttons";
import { Modal, ModalBody } from "../../shared/ui/Modals";

import {
  FlexContainer,
  GridContainer,
  ScrollContainer,
} from "../../shared/ui/Containers";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { PopOver } from "../../shared/ui/Modals";
import { ControlledTooltip, ToolTipItem } from "../../shared/ui/ToolTips";
import { useKeyPress } from "../../shared/utils/keyPress";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const CALL_CONTENT_OPTIONS = [
  {
    type: TYPE_LIST.DISCOVERY_QUESTIONS,
    label: CONTENT_TYPE[TYPE_LIST.DISCOVERY_QUESTIONS].controlLabel,
  },
  {
    type: TYPE_LIST.OBJECTIONS,
    label: CONTENT_TYPE[TYPE_LIST.OBJECTIONS].controlLabel,
  },
  {
    type: TYPE_LIST.SOCIALPROOFS,
    label: CONTENT_TYPE[TYPE_LIST.SOCIALPROOFS].controlLabel,
  },
  {
    type: TYPE_LIST.BATTLESCARDS,
    label: CONTENT_TYPE[TYPE_LIST.BATTLESCARDS].controlLabel,
  },
  {
    type: TYPE_LIST.FAQS,
    label: CONTENT_TYPE[TYPE_LIST.FAQS].controlLabel,
  },
  {
    type: ICON_TYPES.note,
    label: "Notes",
  },
];

const ListItemContainer = styled.div`
  font-size: ${TYPOGRAPHY.standard}px;
  padding: ${SPACING.XS}px;
  padding-left: ${SPACING.SM}px;
  position: relative;
  width: 100%;
  &:hover,
  &.active {
    background: ${({theme}) => theme?.colors?.active || COLORS.active};
  }

`;

const ListContainer = styled.div`
  overflow-y: auto;
  width: 373px;
  min-height: 100px;
  max-height: 200px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.type == APP_LAYOUT.ZOOM ? COLORS.quickSilver : theme?.colors?.grey1  || COLORS.grey1};
    border-radius: 10px;
    cursor: pointer;
    margin-right: 2px;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const Container = styled.div`
  ${(props) => (!props.hideActions ? `padding: ${SPACING.SM}px ${SPACING.SM}px;`: "")}
  border-radius: ${BORDER.radius_lg}px;
  ${(props) => (!props.hideActions ? `border: 1px solid ${props.theme.border.color};`: "")}
  background-color: ${({theme}) => theme.type == APP_LAYOUT.ZOOM ? "#1f1c28": 'transparent'};
  min-width: 380px;
`;

const OptionButton = styled(Button)`
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  i {
    color: ${({theme}) => theme.type == APP_LAYOUT.ZOOM  ? theme.text.color  : theme?.colors?.primary || COLORS.primary}
  }
  &:hover,
  &.active, &:focus {
    border: 1px solid ${({theme}) => theme.border.color };
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const SearchInput = styled(InputForm)`
  background-color: ${({theme}) => theme.type == APP_LAYOUT.ZOOM ? "#1f1c28": '#fff'} ;
  color:${({theme}) => theme.text.color} ;
`;

const AnalyticsBtn = styled.div`
  padding: ${SPACING.SM}px;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background-color: ${({theme}) => theme?.colors.secondary || COLORS.purple};
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 8px solid ${({theme}) => theme.type == APP_LAYOUT.ZOOM ? "#191821" : theme?.colors?.backgroundColor || "#191821"};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover, &.active {
    background: ${({theme}) => theme?.colors?.dark || COLORS.azureRadiance };
  }
  i {
    cursor: pointer;
  }
`;



const ListItem = ({ index, handleSelect, active, val, label }) => {
  const itemRef = useRef();

  useEffect(() => {
    if (active) {
      // itemRef.focus()
    }
  }, [active]);
  return (
    <ListItemContainer
      ref={itemRef}
      className={active ? "active" : ""}
      // tag="button"
      onMouseDown={handleSelect}
      style={{ cursor: "pointer" }}
      key={index}
      // role="button"
      ariaSelected={active}
      active={active}
      tabIndex={active ? (index > 4 ? -1 : 0) : index}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          itemRef.target.blur();
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {val?.type && <Icon type={val?.type} style={{ marginRight: 8 }} />} {label || val?.label}
      </div>
    </ListItemContainer>
  );
};

export const NotesModal = ({ isOpen, toggle, notes, isLive, onSave, disabled }) => {
  const [notesUpdate, setNotesUpdate] = useState(notes);
  const handleChange = (e) => {
    const { value } = e.target;
    setNotesUpdate(value);
  };

  useEffect(() => {
    setNotesUpdate(notes);
    return () => {
      setNotesUpdate("");
    };
  }, [notes]);
  
  return (
    <Modal title={"Notes"} isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <InputForm
          disabled={disabled}
          type={"textarea"}
          value={notesUpdate}
          placeholder={disabled ? "No notes" : "Add note here..."}
          onChange={handleChange}
          style={{ minHeight: 200 }}
        />
        {!disabled && <FlexContainer style={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              onSave(notesUpdate);
              toggle();
            }}
          >
            Save
          </Button>
        </FlexContainer>}
      </ModalBody>
    </Modal>
  );
};

const MeetingOptionPanel = ({
  onOptionSelect,
  type,
  isLive,
  showToolTip = true,
  options,
  handleContenOptionSelect,
  allOptions = [],
  onNoteChange,
  notes = "",
  hideActions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [isSearch, setIsSearch] = useState(true);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isNoteAdded, setIsNoteAdded] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(allOptions);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { theme } = useAuth();


  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  let {  accessCode } = useParams();
  const handleChange = (e) => {
    const { value } = e.target;
    if (!isEnter) {
      setText(value);
      if (value.length > 0) {
        setSelectedIndex(0);
        const filteredArr = allOptions.filter((opt) =>
          opt?.label?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filteredArr);
        if (value.charAt(0) === "/") {
          setIsSearch(false);
        }
      } else if (!isSearch) {
        setIsSearch(true);
      } else if (value.length === 0) {
        setFilteredOptions(allOptions);
        setSelectedIndex(0);
      }
    } else {
      setIsEnter(false);
    }
  };

  const onNoteAdd = useCallback(
    (e) => {
      if (!isSearch && e.key === "Enter" && e.shiftKey === false) {
        let notesTemp = notes
          .slice(1)
          .concat((notes.length > 0 ? "\r\n\r\n" : "") + text.slice(1));
        onNoteChange(notesTemp);
        setIsNoteAdded(true);
        setText("");
        setIsSearch(true);
        setTimeout(() => {
          setIsNoteAdded(false);
        }, 1200);
        setIsEnter(true);
      } else {
        if (e.key === "Enter" && e.shiftKey === false) {
          setIsEnter(true);
          onKeyPressChange("select", selectedIndex);
        }
      }
    },
    [isSearch, text, notes, onNoteChange, selectedIndex]
  );

  const onKeyPressChange = (key, i) => {
    switch (key) {
      case "arrowUp":
        setSelectedIndex(
          selectedIndex !== 0 ? selectedIndex - 1 : filteredOptions.length - 1
        );
        return;
      case "arrowDown":
        setSelectedIndex(
          selectedIndex !== filteredOptions.length - 1 ? selectedIndex + 1 : 0
        );
        return;
      case "select":
        handleContenOptionSelect({
          id: filteredOptions[i]?.id,
          type: filteredOptions[i]?.type,
        });
        handleChange({ target: { value: "" } });
        return 
      default:
        throw new Error();
    }
  };

  useEffect(() => {
    if (arrowUpPressed && text.length) {
      onKeyPressChange("arrowUp");
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed && text.length) {
      onKeyPressChange("arrowDown");
    }
  }, [arrowDownPressed]);

  const togglePopover = (popoverInstance) => {
    setIsOpen({
      ...isOpen,
      [popoverInstance]:!isOpen[popoverInstance]
    })
  }

  return (
    <Container id="meeting_container" hideActions={hideActions}>
      <ControlledTooltip
        target="meeting_container"
        isOpen={isNoteAdded}
        placement="top"
      >
        Note Added
      </ControlledTooltip>
      <NotesModal
        isOpen={isNoteOpen}
        toggle={() => setIsNoteOpen(!isNoteOpen)}
        notes={notes}
        onChange={onNoteChange}
        isLive={isLive}
        onSave={onNoteChange}
        disabled={accessCode}
      />
      {isLive && (
        <div
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          <PopOver
            target="meeting_search"
            cardStyle={{
              padding: 0,
              paddingTop: SPACING.SM,
              paddingBottom: SPACING.SM,
              backgroundColor: theme.card.background || COLORS.chambray,
            }}
            placement="top"
            trigger="focus"
            style={{
              visibility:
                isSearch && text && text.length ? "visible" : "hidden",
              width: 380,
            }}
            title={`${filteredOptions?.length} Results`}
          >
            <ListContainer>
              {filteredOptions.map((val, index) => (
                <ListItem
                  handleSelect={() => {
                    handleContenOptionSelect({ id: val?.id, type: val?.type });
                    handleChange({ target: { value: "" } });
                  }}
                  key={index}
                  role="button"
                  active={index === selectedIndex}
                  val={val}
                  index={index}
                />
              ))}
            </ListContainer>
          </PopOver>
          <form style={{ width: "100%" }} id="meeting_search">
            <SearchInput
              type="textarea"
              style={{ maxHeight: 100, minHeight: 50 }}
              value={text}
              onChange={handleChange}
              placeholder={"Search Content or type / for a note"}
              onKeyPress={onNoteAdd}
            />
          </form>
        </div>
      )}
      <FlexContainer
        style={{
          flexDirection: "column",
        }}
      >
        {(type !== TYPE_LIST.SENTENCE && type !== TYPE_LIST.STATS) && !hideActions && 
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: !isLive ? 10 : 0
          }}
        >
          {CALL_CONTENT_OPTIONS.filter((option) =>{
           return ( isLive ? option.type !== TYPE_LIST.STATS : true)
          }).map((option, i) => (
            <div key={i} style={{ height: "100%" }}>
              <ToolTipItem
                label={option.label}
                direction="top"
                itemId={"meeting_option_" + option.type}
                showToolTip={showToolTip}
                style={{ height: "100%", marginLeft: i !== 0 ? 10 : 0 }}
              >
                <OptionButton
                  className={type === option.type ? "active" : ""}
                  onClick={() => {
                    togglePopover(option.type)
                    if (option.type === "note") {
                      setIsNoteOpen(true);
                    } else {
                      onOptionSelect(option.type);
                    }
                    // setOption(option)
                  }}
                >
                  <Icon
                    type={ICON_TYPES[option.type]}
                    style={{ fontSize: TYPOGRAPHY.h3 }}
                  />
                </OptionButton>
              </ToolTipItem>
                <PopOver
                  cardStyle={{
                    padding: 0,
                    paddingTop: SPACING.SM,
                    paddingBottom: SPACING.SM,
                    backgroundColor: theme.card.background || COLORS.chambray,

                  }}
                  target={"meeting_option_" + option.type}
                  placement="top"
                  toggle={() => {
                    togglePopover(option.type)
                  }}
                  isOpen={isLive && option.type !== "note" && isOpen[option.type]}
                  title={CONTENT_TYPE?.[option.type]?.controlLabelPlural}
                >
                  {/* <h4
                    style={{
                      paddingLeft: SPACING.SM,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {CONTENT_TYPE?.[option.type]?.controlLabelPlural}
                  </h4> */}
                  <ListContainer>
                    {options?.length > 0 ? (
                      options.map((val, index) => (
                        <ListItem
                          val={{...val, type}}
                          tag="button"
                          style={{
                            cursor: "pointer",
                          }}
                          key={index}
                          handleSelect={() => {
                            handleContenOptionSelect(val);
                            togglePopover(option.type)
                            // setOptionType(null)
                          }}
                        >
                          {val.label}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem label="(No Options)"/>
                    )}
                  </ListContainer>
                </PopOver>
            </div>
          ))}
        </div>}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            width: "100%",
          }}
        >
          {!isLive && (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                // marginRight: 10,
                position: "relative",
              }}
            >
              <Button
                style={{
                  width: "100%",
                  height: "50px",
                  justifyContent: "center",
                }}
                className={type === "meeting" ? "active" : ""}
                onClick={() => onOptionSelect("meeting")}
              >
                Meeting
              </Button>
              <AnalyticsBtn 
                className={type === TYPE_LIST.STATS ? "active" : ""}
                onClick={() => onOptionSelect(TYPE_LIST.STATS)}>
                <Icon type={TYPE_LIST.STATS} style={{color: "#fff"}} />
              </AnalyticsBtn>
              <Button
                style={{
                  width: "100%",
                  height: "50px",
                  justifyContent: "center",
                }}
                className={type === TYPE_LIST.SENTENCE ? "active" : ""}
                onClick={() => onOptionSelect(TYPE_LIST.SENTENCE, true)}
              >
                Transcript
              </Button>
            </div>
          )}
        </div>
      </FlexContainer>
    </Container>
  );
};

export default MeetingOptionPanel;
