import { Modal as ModalRS, UncontrolledPopover } from "reactstrap";
import styled from "styled-components";
import { APP_LAYOUT, BORDER, COLORS, SPACING, TYPOGRAPHY } from "../constants";
import { Button } from "./Buttons";
import { Card } from "./Cards";
import { FlexContainer } from "./Containers";
import { Icon, ICON_TYPES } from "./Icon";
//MODAL HEADER
const ModalHeaderDefault = (props) => {
  let { children, className, title, toggle, showClose = true } = props;
  return (
    <div className={"modal_header " + className}>
      {title && <h3 >{title}</h3>}
      <div className="modal_header-right">
        {children
          ? children
          : (toggle || !showClose) && (
            <Icon
              className="modal_header-close"
              type={ICON_TYPES.close}
              style={{ fontSize: TYPOGRAPHY.h3 }}
              onClick={toggle ? toggle : undefined}
            />
          )}
      </div>
    </div>
  );
};
ModalHeaderDefault.defaultProps = {
  children: false,
  className: "",
  toggle: false,
  title: false,
};

export const ModalHeader = styled(ModalHeaderDefault)`
  padding: ${SPACING.SM}px ${SPACING.MD}px 0;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ModalBodyDefault = (props) => {
  let { children, className, style } = props;
  return (
    <div className={"modal_body " + className} style={style}>
      {children}
    </div>
  );
};

ModalBodyDefault.defaultProps = {
  children: false,
  className: "",
  style: {},
};

export const ModalBody = styled(ModalBodyDefault)`
  padding: ${SPACING.SM}px ${SPACING.MD}px ${SPACING.MD}px;
`;



const ModalFooterDefault = (props) => {
  let { children, className, style } = props;
  return (
    <div className={"modal_footer " + (className)} style={style}>
      <div className="modal_footer-inner">
        {children}
      </div>
    </div>
  )
}

ModalFooterDefault.defaultProps = {
  children: false,
  className: '',
  style: {}
}

export const ModalFooter = styled(ModalFooterDefault)`
  padding: ${SPACING.SM}px ${SPACING.MD}px ${SPACING.MD}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`


//MODAL
const ModalContainer = ({
  children,
  title,
  className,
  size,
  toggle,
  style,
  isOpen,
  scroll,
  id,
  showClose = true
}) => {
  return (
    <ModalRS
      isOpen={isOpen}
      toggle={toggle}
      style={style}
      centered={true}
      // animation={true}
      className={
        "modal-container " +
        className +
        (size ? " modal-container-" + size : "") +
        (scroll ? "modal-container-scroll" : "")
      }
    >
      <div id={id || ""}>
        {title && <ModalHeader toggle={toggle} title={title} showClose={showClose} />}
        {children}
      </div>
    </ModalRS>
  );
};

ModalContainer.defaultProps = {
  children: false,
  className: "",
  size: false,
  toggle: () => { },
  isOpen: false,
  title: false,
  scroll: false,
  style: {},
};

export const Modal = styled(ModalContainer)`
  .modal-content {
    border-radius: ${BORDER.radius_sm}px;
    background: ${({theme}) => theme?.modal?.background };
    color: ${({theme}) => theme?.modal?.color };

    overflow: hidden;
    justify-content:center;
    display:flex;
    
    width:531px;
  }
  .modal_body{
    max-height: 90vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const PopOverDefault = styled(UncontrolledPopover)`
  width: 400px;
  max-width: 400px;
  font-family: 'Lato', Helvetica, Arial, san-serif;
  background-color: ${({theme}) => theme?.dropdown?.background ||COLORS.sanMarino }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
  .bs-popover-bottom > .popover-arrow::after {
    border-bottom-color: ${({theme}) => theme?.dropdown?.background || COLORS.sanMarino};
  }
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
  .bs-popover-top > .popover-arrow::after {
    border-top-color: ${({theme}) => theme?.dropdown?.background ||COLORS.greyDark};
  }
  .popover {
    background: ${COLORS.transparent};
    max-width: 400px;
    ${(theme) => (theme.type !== APP_LAYOUT.ZOOM && theme?.border?.color) && `border: 1px solid ${theme.border.color};`}
    border-radius: 0;
    z-index: 20;
    box-shadow: 0px 61px 66px rgba(43, 31, 79, 0.07);
    border-radius: ${BORDER.radius_sm}px;
  }
  .popover-inner {
    font-family: 'Lato', Helvetica, Arial, san-serif;
  }
  h3{
    margin-bottom: ${SPACING.SM}px;
  }
`;

const PopOverTitle = styled.h4`
  margin: 0 ${SPACING.SM}px ${SPACING.XS}px;
  font-weight: 700;
  border-bottom: 1px solid ${({theme}) => theme?.border?.color || "#fff"};
  padding-bottom: ${SPACING.XS}px;
  color: 1px solid ${({theme}) => theme?.text?.color || "#fff"};
`;

export const PopOver = (props) => {
  const {
    placement = "bottom",
    target,
    trigger = "legacy",
    children,
    cardStyle,
    style,
    title
  } = props;
  return (
    <PopOverDefault placement={placement} target={target} trigger={trigger} animate={"true"} style={{ ...style }} {...props}>
      <Card style={{ margin: 0, width: "100%", ...cardStyle }}>
        {title && <PopOverTitle>{title}</PopOverTitle>}
        {children}
      </Card>
    </PopOverDefault>
  )
};


export const PopOverItem = styled.div`
  padding: ${SPACING.XXS}px ${SPACING.SM}px;
  margin: 0 -${SPACING.SM}px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.white};
    background-color: ${({theme, danger}) => danger ? theme.colors.danger || COLORS.red : theme.colors.active || COLORS.azureRadiance };
  }
`;

export const DeleteConfirmationModal = ({
  isOpen,
  toggle,
  onDelete,
  label,
  isLoading
}) => {
  return <Modal
    isOpen={isOpen}
    toggle={toggle}
    title={label}
  >
    <ModalBody>
      <h5>Are you sure you want to continue?</h5>
      <FlexContainer style={{ justifyContent: "flex-end" }}>
        <Button onClick={onDelete} color="danger"isLoading={isLoading}>
          Yes
        </Button>
      </FlexContainer>
    </ModalBody>
  </Modal>
}