export const initialState = {
  display: {},
  company: {},
  new: {},
  zoom: {},
  recent: {},
  hiddenTeamFeedTypes: {},
};

export default function memberReducer(state = initialState, action) {
  switch (action.type) {

    case "RECENT_INTERACTIONS" : {
      return {
        ...state,
        recent: {
          ...state.recent,
          ...action.data,
        },
      };
    }
    
    case "DISPLAY_CONTENT": {
      return {
        ...state,
        display: {
          ...state.content?.display,
          ...action.data,
        },
      };
    }

    case "LAYOUT_CONTENT": {
      return {
        ...state,
        layout: action.data ,
      };
    }

    case "COMPANY_CONTENT": {
      return {
        ...state,
        company: {
          ...action.data,
        },
      };
    }

    case "COMPANY_CONTENT_NEW": {
      return {
        ...state,
        new: {
          ...state.new,
          ...action.data,
        },
      };
    }

    case "COMPANY_ROLES": {
      return {
        ...state,
        roles: {
          ...action.data,
        },
      };
    }

    case "ZOOM_CONTENT": {
      return {
        ...state,
        zoom: {
          ...action.data,
        },
      };
    }

    case "UPDATE_USER_SALESFORCE_RECORD_INFO": {
      return {
        ...state,
        salesforce: {
          fields: {
            ...action.data,
          },
        },
      };
    }

    case "HIDE_TEAM_FEED_TYPE": {
      return {
        ...state,
        hiddenTeamFeedTypes: {
          ...state.hiddenTeamFeedTypes,
          [action.data.filter]: action.data,
        },
      };
    }

    default:
      return state;
  }
}
